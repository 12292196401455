<template>
    <div style="padding: 0 3%">
		
		<div style="margin-top: 5%;">
			<el-divider content-position="left">功能列表</el-divider>
			<el-button type="primary" @click="showDialog">修改密码</el-button>
			<el-button type="primary" @click="showPhoneDialog">修改注册手机号</el-button>
		</div>
		
		<el-dialog  title="修改密码" :visible.sync="dialogVisible" width="45%">
			<el-form :model="form" :rules="rules" ref="form" label-width="120px">
				<el-form-item label="原密码" prop="oldPassword">
					<el-input  placeholder="请输入原密码" v-model="form.oldPassword" show-password></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input  placeholder="请输入新密码" v-model="form.newPassword" show-password></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="confirmPassword">
					<el-input  placeholder="请再次输入新密码" v-model="form.confirmPassword" show-password></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="dialogVisible = false">取 消</el-button>
			  <el-button type="primary" @click="submitForm">确 认</el-button>
			</span>
		</el-dialog>
		
		<el-dialog  title="修改注册手机号" :visible.sync="phoneDialog" width="45%">
			<el-form :model="phoneform" :rules="phoneRules" ref="phoneform" label-width="120px">
				<el-form-item label="原注册手机号" prop="contactPhone">
					<el-input  placeholder="请输入原注册手机号" v-model="phoneform.contactPhone"></el-input>
				</el-form-item>
				<el-form-item label="登陆密码" prop="userPassword">
					<el-input  placeholder="请输入登陆密码" v-model="phoneform.userPassword" show-password></el-input>
				</el-form-item>
				<el-form-item label="新注册手机号" prop="newPhone">
					<el-input  placeholder="请输入新注册手机号" v-model="phoneform.newPhone"></el-input>
				</el-form-item>
				<el-form-item label="确认手机号" prop="confirmPhone">
					<el-input  placeholder="请再次输入新注册手机号" v-model="phoneform.confirmPhone"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="phoneDialog = false">取 消</el-button>
			  <el-button type="primary" @click="phoneformSubmit">确 认</el-button>
			</span>
		</el-dialog>
    </div>
</template>
<script>
    export default {
    	name:'mySetting',
        data() {
			
			var checkPassword = (rule,value,callback) =>{
				if(value === '') {
				    callback(new Error('请输入原密码'));
				} else {
				   if(!this.checkPassword(value)){
					  callback(new Error('6-20字节,只允许字母数字下划线')); 
				   }
				  callback();
				}
			};
			
			var checkPassword1 = (rule,value,callback) =>{
				if(value === '') {
				    callback(new Error('请输入新密码'));
				} else {
				   if(!this.checkPassword(value)){
					  callback(new Error('6-20字节,只允许字母数字下划线')); 
				   }
				  if (this.form.confirmPassword !== '') {
					this.$refs.form.validateField('confirmPassword');
				  }
				  callback();
				}
			};
			
			var checkPassword2 = (rule,value,callback) =>{
				if (value === '') {
				  callback(new Error('请再次输入新密码'));
				} else if (value !== this.form.newPassword) {
				  callback(new Error('两次输入密码不一致!'));
				} else {
				  callback();
				}
			};
			
			
			
			var checkPhone = (rule,value,callback) =>{
				if(value === '') {
				    callback(new Error('请输入手机号码'));
				}
				if(!this.checkMobile(value)){
					callback(new Error('手机号码格式错误'));
				}else{
					callback();
				}
			};
			
			var confirmNewPhone = (rule,value,callback) =>{
				if(value === '') {
					callback(new Error('请输入手机号码'));
				}
				if(!this.checkMobile(value)){
					callback(new Error('手机号码格式错误'));
				}
				if (value !== this.phoneform.newPhone) {
				  callback(new Error('两次输入手机号不一致!'));
				} 
				if(value){
					//请求后台判断手机号是否已注册
					this.HTTP.get('/user/existsUserPhone?userPhone='+value).then(res=>{
						if(res.data.code==1){
							callback();
						}else{
							callback(new Error('手机号码已被注册'));
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}
			};
			
			var checkNewPhone = (rule,value,callback) =>{
				if(value === '') {
					callback(new Error('请输入手机号码'));
				}
				if(!this.checkMobile(value)){
					callback(new Error('手机号码格式错误'));
				}
				if(value){
					//请求后台判断手机号是否已注册
					this.HTTP.get('/user/existsUserPhone?userPhone='+value).then(res=>{
						if(res.data.code==1){
							callback();
						}else{
							callback(new Error('手机号码已被注册'));
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}
			};
			
    		return {
				dialogVisible:false,
				form:{
					oldPassword:'',
					newPassword:'',
					confirmPassword:''
				},
				rules:{
					oldPassword: [{ validator: checkPassword, required: true, trigger: 'blur' },{ min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }],
					newPassword: [{ validator: checkPassword1, required: true, trigger: 'blur' },{ min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }],
					confirmPassword: [{ validator: checkPassword2, required: true, trigger: 'blur' },{ min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }],
				},
				
				
				phoneDialog:false,
				phoneform:{
					contactPhone:'',
					userPassword:'',
					newPhone:'',
					confirmPhone:''
				},
				phoneRules:{
					contactPhone: [{ validator:checkPhone, required: true, trigger: 'blur' },{ min: 11, max: 11, message: '手机号码为11个字符', trigger: 'blur' }],
					userPassword: [{ validator: checkPassword, required: true, trigger: 'blur' },{ min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }],
					newPhone:  [{ validator:checkNewPhone,required: true, trigger: 'blur' },{ min: 11, max: 11, message: '手机号码为11个字符', trigger: 'blur' }],
 					confirmPhone: [{validator:confirmNewPhone,required: true, trigger: 'blur' },{ min: 11, max: 11, message: '手机号码为11个字符', trigger: 'blur' }],
				},
				
			}
        },
        created() {
        },
        methods: {
			showDialog:function(){
				this.dialogVisible = true;
				this.form = {
					oldPassword:'',
					newPassword:'',
					confirmPassword:''
				}
				if(this.$refs['form']!=undefined){
					 this.$refs['form'].resetFields();
				}
			},
			showPhoneDialog:function(){
				this.phoneDialog = true,
				this.phoneform={
					contactPhone:'',
					userPassword:'',
					newPhone:'',
					confirmPhone:'',
				}
				if(this.$refs['phoneform']!=undefined){
					 this.$refs['phoneform'].resetFields();
				}
			},
			checkPassword:function(str){
				var re = /^\w{5,19}$/;
				if(re.test(str)) {
					return true;
				}else {
					return false;
				}
			},
			checkMobile:function(str){
				var re = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
				if(re.test(str)) {
					return true;
				}else {
					return false;
				}
			},
			submitForm:function(){
				this.$refs['form'].validate((valid) => {
					if(valid){
						this.HTTP.post('/user/updatePassword',this.form).then(res=>{
							if(res.data.code==1){
								this.$message.success('修改成功')
								this.dialogVisible = false;
								this.loginout();
							}else{
								this.$message.error(res.data.message);
							}
						}).catch(err=>{
							this.$message.error(err);
						})
					}
				})
			},
			phoneformSubmit:function(){
				this.$refs['phoneform'].validate((valid) => {
					if(valid){
						this.HTTP.post('/user/updatePhone',this.phoneform).then(res=>{
							console.log(res);
							if(res.data.code===1){
								this.$message.success('修改成功')
								this.phoneDialog = false;
								this.loginout();
							}else{
								this.$message.error(res.data.message);
							}
						}).catch(err=>{
							this.$message.error(err);
						})
					}
				})
			},
			loginout:function(){
				sessionStorage.removeItem("userinfo");
				sessionStorage.removeItem("userMsg");
				sessionStorage.removeItem("token");
				this.$store.state.token='';
				this.user = ''
				this.$router.push("/login")
			}
			
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .userInfo_list .grid-content {
        line-height: 20px;
    }

    .userInfo_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .userInfo_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
	.el-input{
		width: 220px;
	}
</style>
